import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import * as React from "react";
import HeaderLottie from "./components/Lottie/HeaderLottie";
// @ts-ignore
import classes from "./Landing.module.css";
import loadable from "@loadable/component";

const StoryOfAmarillo = loadable(() => import("../StoryOfAmarillo/StoryOfAmarillo"));
const FlavorSpectrums = loadable(() => import("../FlavorSpectrums/FlavorSpectrums"));
const AmarilloBright = loadable(() => import("../AmarilloBright/AmarilloBright"));
const FunFacts = loadable(() => import("../FunFacts/FunFacts"));
const AmarilloHop = loadable(() => import("../AmarilloHop/AmarilloHop"));
const Form = loadable(() => import("../Form/Form"));

const Landing = () => (
  <StaticQuery
    query={graphql`
      query {
        second: file(relativePath: { eq: "WoodBG-min.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        third: file(relativePath: { eq: "beer.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData
      const secondData = data.second.childImageSharp.fluid;
      const thirdData = data.third.childImageSharp.fluid;

      return (
        <div className={classes.pageWrapper}>
          <section id="landing">
            <div className={classes.bgParallax} />
            <div className={classes.sectionWrapperFirst}>
              <HeaderLottie />
            </div>
          </section>
          <BackgroundImage
            Tag="section"
            fluid={secondData}
            style={{
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}>
            <div className={classes.sectionWrapperSecond}>
              <StoryOfAmarillo />
              {/* <FlavorProfiles /> */}
              <FlavorSpectrums />
              <AmarilloBright />
              {/* <FunFacts /> */}
            </div>
          </BackgroundImage>
          <BackgroundImage
            Tag="section"
            fluid={thirdData}
            style={{
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}>
            <div className={classes.sectionWrapperThird}>
              <AmarilloHop />
              <div className={classes.formWrapper}>
                <Form netlifyTitle={"Landing Form"} />
              </div>
            </div>
          </BackgroundImage>
        </div>
      );
    }}
  />
);

export default Landing;
