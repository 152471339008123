import * as React from "react";
import Seo from "../components/SEO/Seo";
import { withLayout } from "../components/Layout";
import "../css/styles.css";
import Landing from "../components/Landing/Landing";
import { SEOPagesDescription } from "../utils/seo.util"

const IndexPage = () => {
  return (
    <React.Fragment>
      <Seo {...SEOPagesDescription["home-page"]} />
      <Landing />
    </React.Fragment>
  );
};

export default withLayout(IndexPage);
