import img_0 from "../../../../images/headerLottie/img_0.png";
import img_1 from "../../../../images/headerLottie/img_1.png";
import img_2 from "../../../../images/headerLottie/img_2.png";
import img_3 from "../../../../images/headerLottie/img_3.png";
import img_4 from "../../../../images/headerLottie/img_4.png";
import img_5 from "../../../../images/headerLottie/img_5.png";
import img_6 from "../../../../images/headerLottie/img_6.png";
import img_7 from "../../../../images/headerLottie/img_7.png";
import img_8 from "../../../../images/headerLottie/img_8.png";
import img_9 from "../../../../images/headerLottie/img_9.png";
import img_10 from "../../../../images/headerLottie/img_10.png";
import img_11 from "../../../../images/headerLottie/img_11.png";
import img_12 from "../../../../images/headerLottie/img_12.png";
import img_13 from "../../../../images/headerLottie/img_13.png";
import img_14 from "../../../../images/headerLottie/img_14.png";
import img_15 from "../../../../images/headerLottie/img_15.png";
import img_16 from "../../../../images/headerLottie/img_16.png";
import img_17 from "../../../../images/headerLottie/img_17.png";
import img_18 from "../../../../images/headerLottie/img_18.png";
import img_19 from "../../../../images/headerLottie/img_19.png";
import img_20 from "../../../../images/headerLottie/img_20.png";
import img_21 from "../../../../images/headerLottie/img_21.png";
import img_22 from "../../../../images/headerLottie/img_22.png";
import img_23 from "../../../../images/headerLottie/img_23.png";

export default {
  v: "5.6.3",
  fr: 24,
  ip: 0,
  op: 97,
  w: 800,
  h: 800,
  nm: "Header_Animation",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 1920,
      h: 1360,
      u: "",
      p: img_0,
      e: 0
    },
    {
      id: "image_1",
      w: 800,
      h: 800,
      u: "",
      p: img_1,
      e: 0
    },
    {
      id: "image_2",
      w: 800,
      h: 800,
      u: "",
      p: img_2,
      e: 0
    },
    {
      id: "image_3",
      w: 800,
      h: 800,
      u: "",
      p: img_3,
      e: 0
    },
    {
      id: "image_4",
      w: 800,
      h: 800,
      u: "",
      p: img_4,
      e: 0
    },
    {
      id: "image_5",
      w: 800,
      h: 800,
      u: "",
      p: img_5,
      e: 0
    },
    {
      id: "image_6",
      w: 800,
      h: 800,
      u: "",
      p: img_6,
      e: 0
    },
    {
      id: "image_7",
      w: 800,
      h: 800,
      u: "",
      p: img_7,
      e: 0
    },
    {
      id: "image_8",
      w: 800,
      h: 800,
      u: "",
      p: img_8,
      e: 0
    },
    {
      id: "image_9",
      w: 800,
      h: 800,
      u: "",
      p: img_9,
      e: 0
    },
    {
      id: "image_10",
      w: 800,
      h: 800,
      u: "",
      p: img_10,
      e: 0
    },
    {
      id: "image_11",
      w: 800,
      h: 800,
      u: "",
      p: img_11,
      e: 0
    },
    {
      id: "image_12",
      w: 800,
      h: 800,
      u: "",
      p: img_12,
      e: 0
    },
    {
      id: "image_13",
      w: 800,
      h: 800,
      u: "",
      p: img_13,
      e: 0
    },
    {
      id: "image_14",
      w: 800,
      h: 800,
      u: "",
      p: img_14,
      e: 0
    },
    {
      id: "image_15",
      w: 800,
      h: 800,
      u: "",
      p: img_15,
      e: 0
    },
    {
      id: "image_16",
      w: 800,
      h: 800,
      u: "",
      p: img_16,
      e: 0
    },
    {
      id: "image_17",
      w: 800,
      h: 800,
      u: "",
      p: img_17,
      e: 0
    },
    {
      id: "image_18",
      w: 800,
      h: 800,
      u: "",
      p: img_18,
      e: 0
    },
    {
      id: "image_19",
      w: 800,
      h: 800,
      u: "",
      p: img_19,
      e: 0
    },
    {
      id: "image_20",
      w: 800,
      h: 800,
      u: "",
      p: img_20,
      e: 0
    },
    {
      id: "image_21",
      w: 800,
      h: 800,
      u: "",
      p: img_21,
      e: 0
    },
    {
      id: "image_22",
      w: 800,
      h: 800,
      u: "",
      p: img_22,
      e: 0
    },
    {
      id: "image_23",
      w: 800,
      h: 800,
      u: "",
      p: img_23,
      e: 0
    },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "glow",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [100]
                },
                { t: 48, s: [0] }
              ],
              ix: 11,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [680, 680, 0], ix: 2 },
            a: { a: 0, k: [680, 680, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          w: 1360,
          h: 1360,
          ip: 0,
          op: 240,
          st: 0,
          bm: 3
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "Mid",
          refId: "image_15",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [100]
                },
                { t: 48, s: [0] }
              ],
              ix: 11,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [4]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [-4]
                },
                { t: 48, s: [4] }
              ],
              ix: 10,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            p: { a: 0, k: [650, 1026, 0], ix: 2 },
            a: { a: 0, k: [389.412, 651.765, 0], ix: 1 },
            s: { a: 0, k: [100, 124.606, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 3
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "left",
          refId: "image_16",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [100]
                },
                { t: 48, s: [0] }
              ],
              ix: 11,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: -8,
                  s: [-8]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [8]
                },
                { t: 40, s: [-8] }
              ],
              ix: 10,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            p: { a: 0, k: [648, 1012, 0], ix: 2 },
            a: { a: 0, k: [378.824, 590.588, 0], ix: 1 },
            s: { a: 0, k: [100, 137.88, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 3
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "right",
          refId: "image_17",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [100]
                },
                { t: 48, s: [0] }
              ],
              ix: 11,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: -14,
                  s: [8]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 10,
                  s: [-8]
                },
                { t: 34, s: [8] }
              ],
              ix: 10,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            p: { a: 0, k: [664, 1016, 0], ix: 2 },
            a: { a: 0, k: [395.294, 705.882, 0], ix: 1 },
            s: { a: 0, k: [100, 108, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 3
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "Big",
          refId: "image_18",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [100]
                },
                { t: 48, s: [0] }
              ],
              ix: 11,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [-8]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 24,
                  s: [8]
                },
                { t: 48, s: [-8] }
              ],
              ix: 10,
              x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
            },
            p: { a: 0, k: [656, 1012, 0], ix: 2 },
            a: { a: 0, k: [385.882, 712.941, 0], ix: 1 },
            s: { a: 0, k: [100, 108, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: "top",
          refId: "image_19",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [680, 680, 0], ix: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "Layer 1 copy 7",
          refId: "image_11",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [680, 680, 0], ix: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "Layer 1 copy 6",
          refId: "image_12",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [680, 680, 0], ix: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "Layer 1 copy 5",
          refId: "image_13",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [680, 680, 0], ix: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "Layer 1 copy 4",
          refId: "image_14",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [680, 680, 0], ix: 2 },
            a: { a: 0, k: [400, 400, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "Fulllogo.png",
      cl: "png",
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        a: { a: 0, k: [960, 680, 0], ix: 1 },
        s: { a: 0, k: [50, 50, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "cap",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "Leaf 2",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -6,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 18,
              s: [7]
            },
            { t: 42, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [459.907, 138.682, 0], ix: 2 },
        a: { a: 0, k: [470.534, 121.578, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "Leaf 5",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -4,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 20,
              s: [7]
            },
            { t: 44, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [383.223, 724.015, 0], ix: 2 },
        a: { a: 0, k: [414.849, 726.682, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "Hop 4 copy",
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 24,
              s: [7]
            },
            { t: 48, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [303.471, 634.432, 0], ix: 2 },
        a: { a: 0, k: [322.042, 632.019, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "Hop 4",
      refId: "image_5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 24,
              s: [7]
            },
            { t: 48, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [427.731, 143.169, 0], ix: 2 },
        a: { a: 0, k: [444.548, 151.276, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "Hop 3",
      refId: "image_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 24,
              s: [7]
            },
            { t: 48, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [488.441, 427.508, 0], ix: 2 },
        a: { a: 0, k: [464.965, 453.828, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "Hop1",
      refId: "image_7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 24,
              s: [7]
            },
            { t: 48, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [304.896, 311.694, 0], ix: 2 },
        a: { a: 0, k: [279.35, 312.761, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "Leaf 3",
      refId: "image_8",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -6,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 18,
              s: [7]
            },
            { t: 42, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [515.684, 443.596, 0], ix: 2 },
        a: { a: 0, k: [479.814, 466.821, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "vines",
      refId: "image_9",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "mask",
      td: 1,
      refId: "image_10",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: "Beer",
      tt: 1,
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 400, 0], ix: 2 },
        a: { a: 0, k: [680, 680, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      w: 1360,
      h: 1360,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "Hop 5",
      refId: "image_20",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -12,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 12,
              s: [7]
            },
            { t: 36, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [297.782, 485.151, 0], ix: 2 },
        a: { a: 0, k: [325.754, 485.383, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "Hop 2",
      refId: "image_21",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -12,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 12,
              s: [7]
            },
            { t: 36, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [298.139, 309.74, 0], ix: 2 },
        a: { a: 0, k: [294.2, 277.494, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "Leaf 4",
      refId: "image_22",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -18,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 6,
              s: [7]
            },
            { t: 30, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [309.16, 675.323, 0], ix: 2 },
        a: { a: 0, k: [318.329, 594.896, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: "Leaf 1",
      refId: "image_23",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: -14,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 10,
              s: [7]
            },
            { t: 34, s: [0] }
          ],
          ix: 10,
          x: "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        p: { a: 0, k: [301.828, 311.694, 0], ix: 2 },
        a: { a: 0, k: [290.487, 312.761, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
