import React from "react";
import Lottie from "react-lottie";
// @ts-ignore
import animationData from "./data.js";
// @ts-ignore
import classes from "./HeaderLottie.module.css";

const HeaderLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  };

  return (
    <div className={classes.root}>
      <Lottie options={defaultOptions} isStopped={false} isPaused={false} isClickToPauseDisabled={true} />
    </div>
  );
};

export default HeaderLottie;
